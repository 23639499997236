import React, { ReactElement, useEffect, useRef, useState } from 'react'
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
	ChartArea,
	CoreChartOptions,
	DatasetChartOptions,
	ElementChartOptions,
	PluginChartOptions,
	ScaleChartOptions,
	LineControllerChartOptions,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import {
	dark,
	dark10,
	dark25,
	dark50,
	dark75,
	green,
	red100,
	orange,
} from 'lib/Colors'
import Color from 'color'
import moment from 'moment'
import { useDashboard } from 'hooks/useDashboard'
import { IDailyReview } from 'store/dashboard'
import { isEqual } from 'lodash'
import { Col, Typography } from '@copyrightagent/basic-components'
import annotationPlugin from 'chartjs-plugin-annotation'
import { _DeepPartialObject } from 'chart.js/types/utils' //eslint-disable-line
import { useCaseStatistics } from 'hooks/useCaseStatistics'
import useStyle from './style'

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
	annotationPlugin,
)
ChartJS.defaults.font.family = "'Open Sans'"
ChartJS.defaults.font.weight = '600'
ChartJS.defaults.font.size = 10
interface TooltipData {
	opacity: number
	top: number
	left: number
	date: string
	value: string
}
export default function Chart(): ReactElement {
	const { t } = useTranslation()
	const classes = useStyle()
	const { awaitingReview } = useCaseStatistics()
	const { reviewTarget } = useDashboard()
	const [thisMonthData, setThisMonthData] = useState<Array<number>>([])
	const [lastMonthData, setLastMonthData] = useState<Array<number>>([])
	const [maxValue, setMaxValue] = useState<number>(100)
	const [tooltip, setTooltip] = useState<TooltipData>({
		opacity: 0,
		top: 0,
		left: 0,
		date: '',
		value: '',
	})
	const chart = useRef(null)

	const totalApprovedCases = reviewTarget.approvedThisMonth?.totalInMonth || 0
	const monthlyTarget = reviewTarget.monthlyTarget

	useEffect(() => {
		const minValue = Math.ceil(reviewTarget.monthlyTarget * 1.2)
		if (!reviewTarget.approvedLastMonth) return setMaxValue(minValue)
		const max = Math.max(
			minValue,
			reviewTarget.approvedThisMonth?.totalInMonth || 0,
			reviewTarget.approvedLastMonth.totalInMonth || 0,
		)
		setMaxValue(max)
	}, [reviewTarget.monthlyTarget])

	useEffect(() => {
		if (!reviewTarget.approvedThisMonth) return
		const today = moment().date()
		const data = accumulateMonthData(
			reviewTarget.approvedThisMonth.totalByDay,
			today,
		)
		setThisMonthData(data)
	}, [reviewTarget.approvedThisMonth])

	useEffect(() => {
		if (!reviewTarget.approvedLastMonth) return
		const lastMonthLength = moment().subtract(1, 'months').daysInMonth()
		const data = accumulateMonthData(
			reviewTarget.approvedLastMonth.totalByDay,
			lastMonthLength,
		)
		setLastMonthData(data)
	}, [reviewTarget.approvedLastMonth])

	const labels = getLabels(t('dashboard/targetStats/today'))

	const options: _DeepPartialObject<
		CoreChartOptions<'line'> &
			ElementChartOptions<'line'> &
			PluginChartOptions<'line'> &
			DatasetChartOptions<'line'> &
			ScaleChartOptions<'line'> &
			LineControllerChartOptions
	> = {
		responsive: true,
		scales: {
			x: {
				grid: {
					display: false,
				},
				ticks: {
					autoSkip: false,
					callback(_val, index) {
						if (
							index === 0 ||
							index === moment().daysInMonth() - 1 ||
							index === moment().date() - 1
						)
							return this.getLabelForValue(index)
						return ''
					},
					maxRotation: 0,
					minRotation: 0,
					color: dark50,
				},
			},
			y: {
				min: 0,
				max: maxValue,
				ticks: {
					stepSize: getStepSize(maxValue),
					color: (c) => {
						if (c.tick.value === monthlyTarget) return dark
						return dark25
					},
				},
				grid: {
					drawBorder: false,
				},
			},
		},
		elements: {
			point: {
				radius: 0,
				hoverBackgroundColor: green,
			},
			line: {
				cubicInterpolationMode: 'monotone',
				tension: 0.4,
			},
		},
		plugins: {
			legend: {
				position: 'bottom',
				labels: {
					usePointStyle: true,
					boxWidth: 12,
					boxHeight: 12,
					color: dark75,
					padding: 12,
					boxPadding: 12,
					filter(item) {
						if (awaitingReview.count !== 0) return true
						return !item.text.includes('Current period')
					},
					font: {
						lineHeight: 12,
					},
				},
				title: {
					padding: 12,
				},
			},
			filler: {
				propagate: false,
			},
			tooltip: {
				enabled: false,
				external: (context) => {
					const tooltipModel = context.tooltip
					if (!chart || !chart.current) return

					if (tooltipModel.opacity === 0) {
						if (tooltip.opacity !== 0)
							setTooltip((prev) => ({ ...prev, opacity: 0 }))
						return
					}
					const position = context.chart.canvas.getBoundingClientRect()
					const newTooltipData = {
						opacity: 1,
						left: position.left + tooltipModel.caretX + 4,
						top: position.top + tooltipModel.caretY + 4,
						date: tooltipModel.dataPoints[0].label,
						value: tooltipModel.dataPoints[0].formattedValue,
					}
					if (!isEqual(tooltip, newTooltipData)) setTooltip(newTooltipData)
				},
			},
			annotation: {
				annotations: {
					target: {
						type: 'line',
						yMin: monthlyTarget,
						yMax: monthlyTarget,
						borderColor: green,
						borderWidth: 1,
						borderDash: [5, 5],
					},
				},
			},
		},
		interaction: {
			intersect: false,
			mode: 'point',
		},
		hover: {
			intersect: false,
		},
		maintainAspectRatio: false,
	}
	const getGradient = (ctx: CanvasRenderingContext2D, chartArea: ChartArea) => {
		const gradient = ctx.createLinearGradient(
			0,
			chartArea.bottom,
			0,
			chartArea.top,
		)
		const mainColor = getMainColor()
		gradient.addColorStop(0, Color(mainColor).alpha(0).toString())
		gradient.addColorStop(1, Color(mainColor).alpha(0.2).toString())
		return gradient
	}

	const getMainColor = () => {
		if (totalApprovedCases >= monthlyTarget) return green
		return totalApprovedCases < monthlyTarget / 2 ? red100 : orange
	}

	return (
		<>
			<Line
				ref={chart}
				options={options}
				style={{
					maxWidth: '100%',
				}}
				data={{
					labels,
					datasets: [
						{
							label: t('dashboard/targetStats/lastPeriod'),
							borderColor: dark10,
							backgroundColor: dark10,
							data: lastMonthData,
							order: 2,
							pointRadius: 0,
							pointHitRadius: 0,
						},
						{
							label: t('dashboard/targetStats/currentPeriod'),
							borderColor: getMainColor(),
							backgroundColor(context) {
								const { ctx, chartArea } = context.chart
								if (!chartArea) return
								return getGradient(ctx, chartArea)
							},
							data: thisMonthData,
							fill: 'start',
							order: 1,
							pointBackgroundColor: getMainColor(),
							pointHitRadius: 20,
							hidden: awaitingReview.count === 0,
							pointRadius: 2,
							pointHoverRadius: 2,
							pointHoverBackgroundColor: getMainColor(),
						},
					],
				}}
			/>
			<Col
				className={classes.tooltip}
				style={{
					top: tooltip.top,
					left: tooltip.left,
					opacity: tooltip.opacity,
				}}
			>
				<Typography variant="body2" className={classes.tooltipTitle}>
					{tooltip.date}
					{tooltip.date !== t('dashboard/targetStats/today')
						? `, ${moment().format('YYYY').slice(2, 4)}`
						: ''}
				</Typography>
				<span className={classes.tooltipDivider}></span>
				<Typography variant="body2" className={classes.tooltipValue}>
					{tooltip.value} {t('dashboard/targetStats/cases')}
				</Typography>
			</Col>
		</>
	)
}

function getLabels(todayString: string): Array<string> {
	const daysOfCurrentMonth = moment().daysInMonth()
	const days = Array.from(Array(daysOfCurrentMonth).keys())
	const month = moment().format('MMMM').slice(0, 3)
	const labels = days.map((day) => {
		if (day === moment().date() - 1) return todayString
		return `${day + 1}. ${month}`
	})
	return labels
}

function accumulateMonthData(data: IDailyReview, limit: number): Array<number> {
	const currentMonth: Array<number> = []
	let initialValue = 0
	for (let i = 0; i < limit; i++) {
		if (data[i + 1]) initialValue += data[i + 1]
		currentMonth[i] = initialValue
	}
	return currentMonth
}

function getStepSize(maxValue: number) {
	if (maxValue <= 12) return 1
	if (maxValue > 10 && maxValue < 30) return 5
	if (maxValue >= 30 && maxValue <= 120) return 10
	if (maxValue > 120 && maxValue < 260) return 20
	return 50
}
